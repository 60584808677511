//React
import React from 'react'

//Gatsby
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import NavSubUsecases from '../../components/nav/navSubUsecases'
import FeatureQuad from '../../components/marketing/featureQuad'
import AlternatingCallout from '../../components/marketing/alternatingCallout'

//Icons
import { FaArrowRight } from 'react-icons/fa'

let industryData = [
  // {
  //   title: 'Construction',
  //   path: 'construction',
  //   image: 'industryConstruction',
  //   description:
  //     'With construction workers always on-the-go, drive ROI and more effective communication via text messaging',
  // },
  {
    title: 'Real estate',
    path: 'real-estate',
    image: 'industryRealestate',
    description:
      'With your busy, on-the-go schedule, you can nurture and close real estate leads with beautifully designed visual assets',
  },
  {
    title: 'Direct marketing',
    path: 'direct-marketing',
    image: 'industryDirectmarketing',
    description:
      'Drive action — and revenue — from your distributors with product, event, campaign templates and more',
  },
  {
    title: 'Assisted living',
    path: 'assisted-living',
    image: 'industryAssistedliving',
    description:
      'Drive communication with families, residents, and prospects with engaging visuals — and drive better ROI',
  },
  {
    title: 'Gyms',
    path: 'gyms',
    image: 'industryGyms',
    description:
      'Effectively communicate with prospects & customers about new promotions, classes, and amenities — and achieve better loyalty and ROI',
  },

  {
    title: 'Restaurants',
    path: 'restaurants',
    image: 'industryRestaurants',
    description:
      'Stay top-of-mind with your dine-in, take-out and catering customers with visual, social content and more',
  },
  {
    title: 'Salons & Personal services',
    path: 'personal-services',
    image: 'industrySalons',
    description:
      'Acquire new personal service customers and keep current ones coming back with offers, updated, inspiration and more',
  },
  {
    title: 'Retail',
    path: 'retail',
    image: 'industryRetail',
    description:
      'Drive new and repeat retail customer revenue by delivering visual content marketing',
  },
  {
    title: 'Travel & Hospitality',
    path: 'travel',
    image: 'industryHotels',
    description:
      'Immerse your travel customers in inspiration with offers, promotions, lifestyle content and more',
  },
  {
    title: 'HOAs & Property managers',
    path: 'property-management',
    image: 'industryPropertymanagement',
    description:
      'Drive down costs and improve communications with residents when you stay in touch via visual social marketing',
  },
  {
    title: 'Health care',
    path: 'health-care',
    image: 'industryHealthcare',
    description:
      'Drive increased awareness of your medical or dental facility with templated visual assets that showcase your services',
  },
  {
    title: 'Churches',
    path: 'churches',
    image: 'industryChurch',
    description:
      'Nurture your faith community with compelling, customized visual communication',
  },
  {
    title: 'Non-profits',
    path: 'nonprofit',
    image: 'industryNonprofit',
    description:
      'Rally your stakeholders and drive your mission forward with visual social marketing',
  },
]
//split industryData into groups of 2

industryData = industryData.reduce(function (result, value, index, array) {
  if (index % 2 === 0) result.push(array.slice(index, index + 2))
  return result
}, [])

console.log('industryData')
console.log(industryData)
const About = (props) => (
  <Layout>
    <Helmet
      title="Storylava - Visual Content Marketing - Infustries"
      // meta={[
      //   {
      //     name: 'description',
      //     content:
      //       'Learn about how the Picohealth automated mobile health assessment platform helps reduce risk and drive customer confidence for your gym / fitness club',
      //   },
      //   {
      //     name: 'keywords',
      //     content:
      //       'Covid, Covid-19, health, health questionnaire, covid questions, return to work, pandemic, pandemic assessment questions, worker health question, workplace health questions, workplace health, company health, company safety',
      //   },
      // ]}
    />
    {/* <NavSubUsecases /> */}
    <div class="gradient-callout-tropical">
      <div class="container container-page container-about-sub">
        <div class="row">
          <div class="col-lg-10 d-flex flex-column justify-content-center text-center text-lg-left">
            <h1 class="display-2 font-weight-medium white-1">
              Energize your audience
            </h1>
            <p class="display-4 white-4">
              Discover and follow fresh design templates from designers that
              know your industry
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container container-page">
      {industryData.map((industryGroup) => (
        <div class="row my-4">
          {industryGroup.map((industry) => (
            <div class="col-lg-6">
              <div class="d-flex flex-row align-items-center justify-content-start bg-gray-7 border-2-white-1 p-3 rounded-corners">
                <div class="p-2">
                  <Link
                    to={`/industry/${industry.path}/`}
                    class="industry-title h4 text-left"
                  >
                    {industry.title}
                  </Link>

                  <p class="text-left font-weight-light line-height-2 mt-2">
                    {industry.description}
                  </p>
                  <Link
                    to={`/industry/${industry.path}/`}
                    class="btn btn-primary btn-cta mb-1"
                    activeClassName="btn btn-primary btn-cta"
                  >
                    More <FaArrowRight class="btn-arrow" />
                  </Link>
                </div>

                <Link to={`/industry/${industry.path}/`} class="">
                  <Img
                    className="rounded-corners"
                    fixed={props.data[industry.image].childImageSharp.fixed}
                  />
                </Link>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  </Layout>
)

export default About

export const pageQuery = graphql`
  query {
    industryConstruction: file(
      relativePath: {
        eq: "stock/unsplash/daniel-mccullough-HtBlQdxfG9k-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    industryAssistedliving: file(
      relativePath: {
        eq: "stock/unsplash/hush-naidoo-pA0uoltkwao-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    industryGyms: file(
      relativePath: {
        eq: "stock/unsplash/victor-freitas-hOuJYX2K5DA-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    industryRestaurants: file(
      relativePath: { eq: "stock/unsplash/bundo-kim-Pb9bUzH1nD8-unsplash.jpg" }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    industryRetail: file(
      relativePath: {
        eq: "stock/unsplash/clark-street-mercantile-P3pI6xzovu0-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    industryHotels: file(
      relativePath: {
        eq: "stock/unsplash/claire-ward-Fy9JXanwJi0-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    industryDirectmarketing: file(
      relativePath: {
        eq: "stock/unsplash/toa-heftiba-B5Thyv8d3mo-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    industryRealestate: file(
      relativePath: {
        eq: "stock/unsplash/stephen-leonardi-Al9Cl-b7EFU-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    industrySalons: file(
      relativePath: {
        eq: "stock/unsplash/adam-winger-WXmHwPcFamo-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200, cropFocus: NORTH, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    industryPropertymanagement: file(
      relativePath: {
        eq: "stock/unsplash/brandon-griggs-wR11KBaB86U-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    industryHealthcare: file(
      relativePath: {
        eq: "stock/unsplash/national-cancer-institute-L8tWZT4CcVQ-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    industryNonprofit: file(
      relativePath: { eq: "stock/unsplash/anna-earl-ZbuP5oXM_zA-unsplash.jpg" }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    industryChurch: file(
      relativePath: {
        eq: "stock/unsplash/austin-park-VoUUYQfhTXE-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200, cropFocus: CENTER, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
