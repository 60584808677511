//React
import React from 'react'

//Gatsby
import { Link } from 'gatsby'

const sections = [
  { name: 'Assisted Living', path: 'assisted-living' },
  { name: 'Direct Marketing', path: 'direct-marketing' },
  { name: 'Gyms & Health clubs', path: 'gyms' },
  { name: 'Health Care', path: 'health-care' },
  { name: 'Personal Services', path: 'personal-services' },
  { name: 'Property Management', path: 'property-management' },
  { name: 'Real Estate', path: 'real-estate' },
  { name: 'Restaurants', path: 'restaurants' },
  { name: 'Retail', path: 'retail' },
  { name: 'Travel', path: 'travel' },
  { name: 'Churches', path: 'churches' },
  { name: 'Non-profit', path: 'nonprofit' },
]
const Nav = () => (
  <nav class="navbar navbar-sub navbar-expand-lg bg-gray-7  border-top-1-gray-7 border-bottom-1-gray-7 ">
    <div class="container">
      <div
        class="d-flex flex-row align-items-center justify-content-center"
        id="navbarChallenge"
      >
        {/* <div
        class="card-title-solo black-3 text-right pr-4"
        style={{ width: "160px" }}
      >
        Challenges
      </div> */}
        <ul class="navbar-nav nav-subusecase mr-auto d-flex flex-row align-items-center justify-content-center flex-wrap">
          {sections.map((section) => (
            <li class="nav-item pr-3">
              <Link
                class="nav-link font-weight-light black-4"
                activeClassName="nav-link black-4 font-weight-regular active"
                to={`/industry/${section.path}/`}
              >
                {section.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </nav>
)

export default Nav
